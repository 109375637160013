<template>
  <div class="project-tab-attributes-plans">
    <!-- current plan -->
    <v-card class="mb-7">
      <v-card-title>
        <p class="mb-2 mb-sm-0">
          Project Detail
        </p>
        <v-spacer></v-spacer>
          <v-btn
            :color="resolveProjectStatusVariant(projectDataLocal.job_status.name)"
            class="me-3"
            :disabled="!projectDataLocal.editable"
            @click="isProjectAttributesDialogOpen = !isProjectAttributesDialogOpen"
          >
            Edit
          </v-btn>
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col
            cols="12"
            md="6"
          >
            <h4 class="mb-1">
              <span class="font-weight-medium me-1">Departments involved are</span>
              <span>{{departments}}</span>
            </h4>
            <p>The total sample size is N={{projectDataLocal.sample}}</p>

            <h4 class="font-weight-medium mb-1">
              Report is due on <u>{{formatDate(projectDataLocal.report_date)}}</u>
            </h4>
            <p>Closed won date is {{formatDate(projectDataLocal.closed_won_date)}}
            </p>
            <avatar
                :userList="projectDataLocal.insights"
                label="Insights"
                size="40"
              >
              </avatar>
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <v-alert
              :color="resolveProjectStatusVariant(projectDataLocal.job_status.name)"
              text
            >
              <p class="font-weight-semibold mb-1">
                The project is being fielded in {{ fieldCountries }}
              </p>
              <p class="text-sm mb-0">
                From {{formatDate(projectDataLocal.field_start)}} until {{formatDate(projectDataLocal.field_end)}}
              </p>
            </v-alert>

            <p class="d-flex font-weight-semibold text--primary mt-3 mb-2">
              <span>Field Days</span>
              <v-spacer></v-spacer>
              <span>{{projectDataLocal.days_started}} of {{projectDataLocal.days_in_field}} Days</span>
            </p>
            <v-progress-linear
              :value="projectDataLocal.field_progress"
              :color="resolveProjectStatusVariant(projectData.job_status.name)"
              height="8"
              rounded
            ></v-progress-linear>
            <p class="mt-2">
              {{projectDataLocal.days_remaining}} days remaining
            </p>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-title>
        Project Attributes
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col
            cols="12"
            lg="6"
          >
            <table class="attribute-table">
              <tr>
                <td class="attribute-title">
                  <p class="text-no-wrap text--primary font-weight-medium">
                    Project Type:
                  </p>
                </td>
                <td><p>{{ projectDataLocal.project_type.name }}</p></td>
              </tr>
              
              <tr>
                <td class="attribute-title">
                  <p class="text-no-wrap text--primary font-weight-medium">
                    Research Method:
                  </p>
                </td>
                <td>
                  <p>
                    {{researchMethods}}
                  </p>
                </td>
              </tr>
            </table>
          </v-col>
          <v-col
            cols="12"
            lg="6"
          >
            <table class="attribute-table">
              <tr>
                <td class="attribute-title">
                  <p class="text-no-wrap text--primary font-weight-medium">
                    Business Initiative:
                  </p>
                </td>
                <td><p>{{ projectDataLocal.business_initiative.name }}</p></td>
              </tr>
              <tr>
                <td class="attribute-title">
                  <p class="text-no-wrap text--primary font-weight-medium">
                    Service Type:
                  </p>
                </td>
                <td><p>{{ projectDataLocal.service_type.name }}</p></td>
              </tr>
            </table>
          </v-col>
          <v-col
            cols="12"
            lg="12"
          >
            <table class="attribute-table">
              <tr>
                <td class="attribute-title">
                  <p class="text-no-wrap text--primary font-weight-medium">
                    Comments:
                  </p>
                </td>
                <td><p>{{ projectDataLocal.key_comments }}</p></td>
              </tr>
              
            </table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <!-- Country timeline -->
    <v-card 
      v-for="(country, index) in projectDataLocal.countries"
      class="mb-7"
      :style="`border: 2px solid var(--v-${resolveCountryStatusVariant(country.status)}-base !important;`"
      :key="country.id"
    >
      <v-card-title>
       {{country.country_detail.name}}
       <v-chip
          small
          label
          :color="resolveCountryStatusVariant(country.status)"
          :class="`v-chip-light-bg ${resolveCountryStatusVariant(country.status)}--text font-weight-medium text-capitalize ml-2`"
        >
          {{ resolveCountryStatusLabelVariant(country.status) }}
        </v-chip>
        <v-spacer></v-spacer>
          <v-btn
            :color="resolveCountryStatusVariant(country.status)"
            class="me-3"
            :disabled="!projectDataLocal.editable"
            @click="isCountryAttributesDialogOpen = !isCountryAttributesDialogOpen; countryIndex = index"
          >
            Edit
          </v-btn>
          <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
                :disabled="!projectDataLocal.editable"
                v-if="$can('update', 'survey')"
              >
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                :style="`cursor: pointer`"
                  :to="country.questionnaire ? { name: 'survey-project-view', params: { id: projectDataLocal.job_number, country: country.country_detail.code} }: {name: 'survey-view'}"
              >
                <v-list-item-title
                >
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiOrderBoolDescendingVariant }}
                  </v-icon>
                  <span>Survey Builder</span>
                </v-list-item-title>
              </v-list-item>

            </v-list>
          </v-menu>
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col
            cols="12"
            md="6"
          >
            <v-timeline
              dense
              class="timeline-custom-dense timeline-custom-dots"
            >
              <v-timeline-item
                color="error"
                small
              >
                <div class="d-flex justify-space-between align-center flex-wrap">
                  <h4 class="font-weight-medium me-1">
                    Field Start
                  </h4>
                </div>
                <p class="mb-0">
                  {{formatDate(country.field_start_date)}}
                </p>
              </v-timeline-item>
              <v-timeline-item
                color="secondary"
                small
              >
                <div class="d-flex justify-space-between align-center flex-wrap">
                  <h4 class="font-weight-medium me-1">
                    Research Location
                  </h4>
                </div>
                <p class="mb-0">
                  {{country.research_location_detail.name}}
                </p>
              </v-timeline-item>
              <v-timeline-item
                color="accent"
                small
              >
                <div class="d-flex justify-space-between align-center flex-wrap">
                  <h4 class="font-weight-medium me-1">
                    Studio Info
                  </h4>
                </div>
                <div class="d-flex align-center">
                  <div>
                    <span class="font-weight-medium me-1">Shelf Type:</span>
                    <span>{{country.shelf_type_detail.name}}</span>
                  </div>
                </div>
                
                <div class="d-flex align-center">
                  <div>
                    <span class="font-weight-medium me-1">Rate Card:</span>
                    <span>{{country.studio_rate_card}}</span>
                  </div>
                </div>
              
              </v-timeline-item>
              <v-timeline-item
                color="warning"
                small
              >
                <div class="d-flex justify-space-between align-center flex-wrap">
                  <h4 class="font-weight-medium me-1">
                    Composition
                  </h4>
                </div>
                <div class="d-flex align-center">
                  <div>
                    <span class="font-weight-medium me-1">Sample Size:</span>
                    <span>N={{country.sample}}</span>
                  </div>
                </div>
                
                <div class="d-flex align-center">
                  <div>
                    <span class="font-weight-medium me-1">Cells:</span>
                    <span>{{country.phase}}</span>
                  </div>
                </div>
                <div 
                  class="d-flex align-center" 
                  v-if="projectDataLocal.project_type.name === 'Qualitative'"
                >
                  <div>
                    <span class="font-weight-medium me-1">Qual Sample Type:</span>
                    <span>{{arrayToString(country.qual_sample_type)}}</span>
                  </div>
                </div>
              
              </v-timeline-item>
              
            </v-timeline>
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <v-timeline
              dense
              class="timeline-custom-dense timeline-custom-dots"
            >
              <v-timeline-item
                color="info"
                small
              >
                <div class="d-flex justify-space-between align-center flex-wrap">
                  <h4 class="font-weight-medium me-1">
                    Modules
                  </h4>
                </div>
                <p class="mb-0">
                  {{arrayToString(country.modules_detail)}}
                </p>
              </v-timeline-item>
              <v-timeline-item
                color="secondary"
                small
                v-if="[1, 2, 5, 6].includes(country.research_location)"
              >
                <div class="d-flex justify-space-between align-center flex-wrap">
                  <h4 class="font-weight-medium me-1">
                    Field Locations
                  </h4>
                </div>
                <p class="mb-0">
                  {{arrayToString(country.field_locations, 'field_name')}}
                </p>
              </v-timeline-item>
              <v-timeline-item
                color="primary"
                small
              >
                <div class="d-flex justify-space-between align-center flex-wrap">
                  <h4 class="font-weight-medium me-1">
                    Departments
                  </h4>
                </div>

                <!-- Field -->
                <avatar
                  :userList="country.field"
                  label="Field"
                >
                </avatar>
                
                <!-- Studio -->
                <avatar
                  :userList="country.studio"
                  label="Studio"
                >
                </avatar>
                
                <!-- Prgm/DP/Coding -->
                <avatar
                  :userList="country.programming"
                  label="Prgm/DP/Coding"
                >
                </avatar>
                
                <!-- Modeling -->
                <avatar
                  :userList="country.modeling"
                  label="Modeling"
                >
                </avatar>                

                <!-- Proj Mgmt -->
                <avatar
                  :userList="country.project_management"
                  label="Proj Mgmt"
                >
                </avatar>

                <!-- Admin -->
                <avatar
                  :userList="country.admin"
                  label="Admin"
                >
                </avatar>

                <!-- Logistic -->
                <avatar
                  :userList="country.logistic"
                  label="Logistic"
                >
                </avatar>
              </v-timeline-item>
              

              <v-timeline-item
                color="success"
                small
              >
                <div class="d-flex justify-space-between align-center flex-wrap">
                  <h4 class="font-weight-semibold me-1">
                    Field End
                  </h4>
                </div>
                <p class="mb-0">
                  {{formatDate(country.field_end_date)}}
                </p>
              </v-timeline-item>
            </v-timeline>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

     <div class="d-flex align-center flex-wrap"
        v-if="$can('add','project')">
        <v-btn
          :color="resolveProjectStatusVariant(projectDataLocal.job_status.name)"
          class="mb-4 me-3"
          :disabled="!projectDataLocal.editable"
          @click="isCountryAddDialogOpen = !isCountryAddDialogOpen"
        >
          <v-icon>{{ icons.mdiPlus }}</v-icon>
          <span>Add New Market</span>
        </v-btn>

      </div>
      <project-attributes-edit
        :projectData="projectData"
        :isProjectAttributesDialogOpen.sync="isProjectAttributesDialogOpen"
        @update-project="updateProject"
      >
      </project-attributes-edit>
      <country-attributes-edit
        :projectData="projectData"
        :isCountryAttributesDialogOpen.sync="isCountryAttributesDialogOpen"
        :index="countryIndex"
        :csatResult="csatResult"
        @update-project="updateProject"
      >
      </country-attributes-edit>
      <country-add
        :projectData="projectData"
        :isCountryAddDialogOpen.sync="isCountryAddDialogOpen"
        @add-market="addMarket"
      >

      </country-add>
  </div>
</template>

<script>
import {
  mdiPlus,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiDotsVertical,
  mdiOrderBoolDescendingVariant } from '@mdi/js'
import { ref, computed, watch } from '@vue/composition-api'
import { formatDate } from '@core/utils/filter'
import useProjectsList from '../../project-list/useProjectsList'
import useProjectView from '../useProjectView'

import CountryAttributesEdit from './CountryAttributesEdit.vue'
import ProjectAttributesEdit from './ProjectAttributesEdit.vue'
import CountryAdd from './CountryAdd.vue'
import Avatar from '@/views/components/avatar/Avatar.vue'

export default {
  components: {
    CountryAdd,
    ProjectAttributesEdit,
    CountryAttributesEdit,
    Avatar
  },
  props: {
    projectData: {
      type: Object,
      required: true,
    },
    csatResult: {
        type: Array,
        required: true
    }
  },
  setup(props, { emit }) {

    const {
      resolveProjectStatusVariant
    } = useProjectsList()

    const {
      resolveCountryStatusVariant,
      resolveCountryStatusLabelVariant
    } = useProjectView()


    const projectDataLocal = ref(JSON.parse(JSON.stringify(props.projectData)))
    const isProjectAttributesDialogOpen = ref(false)
    const isCountryAttributesDialogOpen = ref(false)
    const isCountryAddDialogOpen = ref(false)


    const departments = computed(() => {
      return arrayToString(projectDataLocal.value.departments)
    })

    const researchMethods = computed(() => {
      return arrayToString(projectDataLocal.value.research_method)
    })

    const fieldCountries = computed(() => {
      return projectDataLocal.value.countries.filter((country) => {
        if (country.status !== 'CLD') return country
      }).map(country => country.country_detail.name).join(", ")
    })

    const countryIndex = ref(0)

    const arrayToString = (array, key='name') => {
      return array.map(item => item[key]).join(', ')
    }

    const updateProject = (data) => {
      emit('update-project', data)
    }

    const addMarket = (data) => {
      emit('add-market', data)
    }

    watch(
      () => props.projectData, 
      () => {
        projectDataLocal.value = JSON.parse(JSON.stringify(props.projectData))
      },
    )


    return {
      projectDataLocal,
      isProjectAttributesDialogOpen,
      isCountryAttributesDialogOpen,
      isCountryAddDialogOpen,
      departments,
      researchMethods,
      fieldCountries,
      countryIndex,
      
      formatDate,
      arrayToString,
      resolveCountryStatusVariant,
      resolveCountryStatusLabelVariant,
      resolveProjectStatusVariant,
      updateProject,
      addMarket,
      icons: {
        mdiPlus,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiDotsVertical,
        mdiOrderBoolDescendingVariant
      },
    }
  },
}
</script>
