<template>
    <div class="swof-tab-overview">
        <!-- project SWOF -->
        <v-card class="swof-tab-overview" flat>
            <v-tabs
                v-model="tab"
                class="country-tabs"
                >
                <v-tab
                    v-for="(country, idx) in countries"
                    :key="idx"
                    @click="initCountrySwof(idx)"
                >
                    {{ country.name }}
                </v-tab>
            </v-tabs>

            <v-tabs-items v-model="tab" id="swof-tabs">
                <v-tab-item
                    v-for="(country, idx) in countries"
                    :key="idx"
                >
                    <v-card class="my-2">
                        <v-card-text>
                            <v-data-table
                                dense
                                :headers="shelfImageHeaders"
                                :items="shelves"
                            >
                                <template v-slot:top>
                                    <v-toolbar
                                        flat
                                    >
                                        <v-toolbar-title>Shelf Images</v-toolbar-title>
                                        
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            small
                                            color="primary"
                                            class="mb-2"
                                            @click="itemDialog = true"
                                            >
                                            New Shelf Image
                                        </v-btn>
                                        
                                    </v-toolbar>
                                    </template>
                                    <template v-slot:[`item.price`]="{ item }">
                                        <v-simple-checkbox
                                            v-model="item.price"
                                            disabled
                                        ></v-simple-checkbox>
                                    </template>
                                    <template v-slot:[`item.boxed`]="{ item }">
                                        <v-simple-checkbox
                                            v-model="item.boxed"
                                            disabled
                                        ></v-simple-checkbox>
                                    </template>
                                    <template v-slot:[`item.actions`]="{ item, index }">
                                        <v-icon
                                            small
                                            class="mr-2"
                                            @click="editItem(shelves, item, index)"
                                        >
                                            {{ icons.mdiPencil }}
                                        </v-icon>
                                        <v-icon
                                            small
                                            @click="deleteItem(shelves, item, index)"
                                        >
                                        {{ icons.mdiDelete }}
                                        </v-icon>
                                        </template>
                                        <template v-slot:no-data>
                                        <v-btn
                                            color="primary"
                                            @click="initialize"
                                            small
                                        >
                                            Reset
                                        </v-btn>
                                    </template>
                        </v-data-table>

                        </v-card-text>
                    </v-card>
                    <v-card class="my-2">
                        <v-card-text>
                            <v-data-table
                                dense
                                :headers="skuTestImageHeaders"
                                :items="skuTests"
                            >
                                <template v-slot:top>
                                    <v-toolbar
                                        flat
                                    >
                                        <v-toolbar-title>SKU Test Images</v-toolbar-title>
                                        
                                        <v-spacer></v-spacer>
                                        <v-col cols="12" sm="4" md="4">
                                            <v-form
                                                @submit="createSkuTests($event, idx)"
                                            >
                                                
                                                <v-text-field
                                                    v-model="skuTestVarietiesCount"
                                                    dense
                                                    outlined
                                                    label="Test Varieties Count"
                                                    type="number"
                                                    hide-details
                                                    class="mr-2"
                                                    :append-outer-icon="icons.mdiCheck"
                                                    @click:append-outer="createSkuTests($event, idx)"
                                                >
                                                </v-text-field>
                                            </v-form>
                                        </v-col>
                                        
                                    </v-toolbar>
                                </template>
                                <template v-slot:[`item.price`]="{ item }">
                                    <v-simple-checkbox
                                        v-model="item.price"
                                        disabled
                                    ></v-simple-checkbox>
                                </template>
                                
                                <template v-slot:[`item.actions`]="{ item, index }">
                                    <v-icon
                                        small
                                        class="mr-2"
                                        @click="editItem(skuTests, item, index)"
                                    >
                                        {{ icons.mdiPencil }}
                                    </v-icon>
                                    <v-icon
                                        small
                                        @click="deleteItem(skuTests, item, index)"
                                    >
                                    {{ icons.mdiDelete }}
                                    </v-icon>
                                    </template>
                                    <template v-slot:no-data>
                                    <v-btn
                                        color="primary"
                                        @click="initialize"
                                        small
                                    >
                                        Reset
                                    </v-btn>
                                </template>
                            </v-data-table>
                        </v-card-text>
                    </v-card>
                    <v-card class="my-2">
                        <v-card-text>
                            <v-data-table
                                dense
                                :headers="skuCompetitorsImageHeaders"
                                :items="skuCompetitors"
                            >
                                <template v-slot:top>
                                    <v-toolbar
                                        flat
                                    >
                                        <v-toolbar-title>SKU Competitor Images</v-toolbar-title>
                                        
                                        <v-spacer></v-spacer>
                                        <v-col cols="12" sm="4" md="4">
                                            <form @submit="createSkuCompetitors"
                                            >
                                                <v-text-field
                                                    v-model="skuCompetitorsCount"
                                                    dense
                                                    outlined
                                                    label="Competitors Count"
                                                    type="number"
                                                    hide-details
                                                    :append-outer-icon="icons.mdiCheck"
                                                    @click:append-outer="createSkuCompetitors"
                                                >
                                                </v-text-field>
                                            </form>
                                        </v-col>
                                    </v-toolbar>
                                </template>
                                <template v-slot:[`item.price`]="{ item }">
                                    <v-simple-checkbox
                                        v-model="item.price"
                                        disabled
                                    ></v-simple-checkbox>
                                </template>
                                
                                <template v-slot:[`item.actions`]="{ item, index }">
                                    <v-icon
                                        small
                                        class="mr-2"
                                        @click="editItem(skuCompetitors, item, index)"
                                    >
                                        {{ icons.mdiPencil }}
                                    </v-icon>
                                    <v-icon
                                        small
                                        @click="deleteItem(skuCompetitors, item, index)"
                                    >
                                    {{ icons.mdiDelete }}
                                    </v-icon>
                                    </template>
                                    <template v-slot:no-data>
                                    <v-btn
                                        color="primary"
                                        @click="initialize"
                                        small
                                    >
                                        Reset
                                    </v-btn>
                                </template>
                            </v-data-table>
                        </v-card-text>
                    </v-card>
                    <v-card class="my-2">
                        <v-card-text>
                            <v-data-table
                                dense
                                :headers="lineImageHeaders"
                                :items="lines"
                            >
                                <template v-slot:top>
                                    <v-toolbar
                                        flat
                                    >
                                        <v-toolbar-title>Line Images</v-toolbar-title>
                                        
                                        <v-spacer></v-spacer>
                                        <v-col cols="12" sm="4" md="4">
                                            <form @submit="createLines($event, idx)"
                                            >
                                                <v-text-field
                                                    v-model="lineCount"
                                                    dense
                                                    outlined
                                                    label="Lines Count"
                                                    type="number"
                                                    hide-details
                                                    :append-outer-icon="icons.mdiCheck"
                                                    @click:append-outer="createLines($event, idx)"
                                                >
                                                </v-text-field>
                                        </form>
                                    </v-col>
                                    </v-toolbar>
                                </template>
                                <template v-slot:[`item.price`]="{ item }">
                                    <v-simple-checkbox
                                        v-model="item.price"
                                        disabled
                                    ></v-simple-checkbox>
                                </template>
                                
                                <template v-slot:[`item.actions`]="{ item, index }">
                                    <v-icon
                                        small
                                        class="mr-2"
                                        @click="editItem(lines, item, index)"
                                    >
                                        {{ icons.mdiPencil }}
                                    </v-icon>
                                    <v-icon
                                        small
                                        @click="deleteItem(lines, item, index)"
                                    >
                                    {{ icons.mdiDelete }}
                                    </v-icon>
                                    </template>
                                    <template v-slot:no-data>
                                    <v-btn
                                        color="primary"
                                        @click="initialize"
                                        small
                                    >
                                        Reset
                                    </v-btn>
                                </template>
                            </v-data-table>
                        </v-card-text>
                    </v-card>

                    <v-card class="my-2">
                        <v-card-text>
                            <v-data-table
                                dense
                                :headers="lineImageHeaders"
                                :items="lineCompetitors"
                            >
                                <template v-slot:top>
                                    <v-toolbar
                                        flat
                                    >
                                        <v-toolbar-title>Line Competitor Images</v-toolbar-title>
                                        
                                        <v-spacer></v-spacer>
                                        <v-col cols="12" sm="4" md="4">
                                            <form @submit="createLineCompetitors"
                                            >
                                                <v-text-field
                                                    v-model="lineCompetitorCount"
                                                    dense
                                                    outlined
                                                    label="Line Competitors Count"
                                                    type="number"
                                                    hide-details
                                                    :append-outer-icon="icons.mdiCheck"
                                                    @click:append-outer="createLineCompetitors"
                                                >
                                                </v-text-field>
                                            </form>
                                    </v-col>
                                    </v-toolbar>
                                </template>
                                <template v-slot:[`item.price`]="{ item }">
                                    <v-simple-checkbox
                                        v-model="item.price"
                                        disabled
                                    ></v-simple-checkbox>
                                </template>
                                
                                <template v-slot:[`item.actions`]="{ item, index }">
                                    <v-icon
                                        small
                                        class="mr-2"
                                        @click="editItem(lineCompetitors, item, index)"
                                    >
                                        {{ icons.mdiPencil }}
                                    </v-icon>
                                    <v-icon
                                        small
                                        @click="deleteItem(lineCompetitors, item, index)"
                                    >
                                    {{ icons.mdiDelete }}
                                    </v-icon>
                                    </template>
                                    <template v-slot:no-data>
                                    <v-btn
                                        color="primary"
                                        @click="initialize"
                                        small
                                    >
                                        Reset
                                    </v-btn>
                                </template>
                            </v-data-table>
                        </v-card-text>
                    </v-card>
                </v-tab-item>
            </v-tabs-items>
            
        </v-card>

        <v-dialog
            v-model="itemDialog"
            max-width="500px"
        >
            <v-card>
                <v-card-title>
                    <span class="text-h5">{{title('shelf')}} - {{ editedItem.label }}</span>
                </v-card-title>

                <v-card-text>
                    <v-container>
                        <v-row>
                            <!-- <v-col
                                cols="12"
                                sm="6"
                                md="4"
                            >
                                <v-text-field
                                v-model="editedItem.label"
                                label="Label"
                                disabled

                                ></v-text-field>
                            </v-col> -->
                            <v-col
                                cols="12"
                                sm="12"
                                md="12"
                            >
                                <v-text-field
                                v-model="editedItem.description"
                                dense
                                outlined
                                label="Description (eg: 'Current', 'Proposed Blue')"
                                hide-details
                                ></v-text-field>
                            </v-col>
                            <v-col
                                cols="12"
                                sm="12"
                                md="12"
                            >
                                <v-text-field
                                v-model="editedItem.questions"
                                dense
                                outlined
                                label="Questions"
                                hide-details
                                ></v-text-field>
                            </v-col>
                            <v-col
                                cols="12"
                                sm="6"
                                md="4"
                            >
                                <v-checkbox
                                v-model="editedItem.price"
                                dense
                                label="Price"
                                hide-details
                                ></v-checkbox>
                            </v-col>
                            <v-col
                                cols="12"
                                sm="6"
                                md="4"
                            >
                                <v-checkbox
                                v-model="editedItem.boxed"
                                dense
                                label="Boxed"
                                hide-details
                                ></v-checkbox>
                            </v-col>
                            
                            <v-col
                                cols="12"
                                sm="6"
                                md="4"
                            >
                                <v-checkbox
                                v-model="editedItem.jpg"
                                dense
                                label="JPG"
                                hide-details
                                ></v-checkbox>
                            </v-col>
                            
                            <v-col
                                cols="12"
                                sm="6"
                                md="4"
                            >
                                <v-text-field
                                    v-model="editedItem.prints"
                                    dense
                                    outlined
                                    label="Prints"
                                    type="number"
                                    hide-details
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>

                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="blue darken-1"
                    text
                    @click="closeItem"
                >
                    Cancel
                </v-btn>
                <v-btn
                    color="primary"
                    text
                    @click="saveItem"
                >
                    Save
                </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="itemDialogDelete" max-width="550px">
            <v-card>
                <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="" small text @click="closeItemDelete">Cancel</v-btn>
                <v-btn color="primary" text small @click="deleteItemConfirm">OK</v-btn>
                <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
      
    </div>
  
</template>

<script>
import store from '@/store'
import { ref, onMounted, onUnmounted, getCurrentInstance, watch, computed, nextTick } from '@vue/composition-api'
import { foundryRequest } from '@/config/authConfig';
import { formatOptions  } from '@core/utils'
import { mdiPencil, mdiDelete, mdiCheck} from '@mdi/js'

export default {
    props: {
        projectData: {
            type: Object,
            required: true,
        },
    },
    setup(props, { emit }){

        const localProjectData = ref({})
        const tab = ref(null)
        const countries = ref([])


        const imageList = ref([])
        const shelves = ref([])
        const itemDialog = ref(false)
        const itemDialogDelete = ref(false)
        const editedIndex = ref(-1)
        const editedItem = ref({
            label: "",
            description:"",
            products:[],
            questions:[],
            lifesizePercent:100,
            jpg:true,
            prints:0,
            price:true,
            boxed:false,
            notes:""}
        )
        const defaultItem = ref({
            label: "",
            description:"",
            products:[],
            questions:[],
            lifesizePercent:100,
            jpg:true,
            prints:0,
            price:true,
            boxed:false,
            notes:""
        })
        
        const shelfImageHeaders = [
            {
                text: 'Label',
                align: 'start',
                value: 'label',
            },
            { text: 'Description (eg: "Current", "Proprosed Blue")', value: 'description' },
            { text: 'Questions', value: 'questions' },
            { text: 'Price', value: 'price' },
            { text: 'Boxed', value: 'boxed' },
            { text: 'Notes', value: 'notes' },
            { text: 'Actions', value: 'actions', sortable: false },
        ]

        const initialize = () => {
            localProjectData.value = JSON.parse(JSON.stringify(props.projectData))
            countries.value = []
            localProjectData.value.countries.forEach(country => {
                countries.value.push( {
                    id: country.id,
                    name: country.country_detail.name,
                    phase: country.phase,

                })

                
            })
            initCountrySwof(0)
        }

        const initCountrySwof = (index) => {
            editedIndex.value = -1
            if (index >= 0 & index < localProjectData.value.countries.length) {
                shelves.value = []
                for(var i=0; i<localProjectData.value.countries[index].phase; i++) {
                    const shelfImage = {
                        label: `SHELF1${String.fromCharCode(64 + i+1) }`,
                        description:`Phase ${i+1}`,
                        products:[],
                        questions:[],
                        lifesizePercent:100,
                        jpg:true,
                        prints:0,
                        price:true,
                        boxed:false,
                        notes:""
                    }
                    shelves.value.push(shelfImage)
                }

                createSkuTests(null, index)
                createSkuCompetitors(null)
                createLines(null, index)
                createLineCompetitors(null)

            }
        }

        const editItem = (list, item, index) => {
            editedIndex.value = index
            editedItem.value = Object.assign({}, item)
            imageList.value = list
            itemDialog.value = true
        }

        const deleteItem = (list, item, index) => {
            editedIndex.value = index
            editedItem.value = Object.assign({}, item)
            imageList.value = list
            itemDialogDelete.value = true
        }

        const deleteItemConfirm = () => {
            imageList.value.splice(editedIndex.value, 1)
            closeItemDelete()
        }

        const closeItem = () => {
            itemDialog.value = false
            nextTick(() => {
            editedItem.value = Object.assign({}, defaultItem.value)
            editedIndex.value = -1
            })
        }

        const closeItemDelete = () => {
            itemDialogDelete.value = false
            nextTick(() => {
            editedItem.value = Object.assign({}, defaultItem.value)
            editedIndex.value = -1
            })
        }

        const saveItem = () => {
            if (editedIndex.value > -1) {
            Object.assign(imageList.value[editedIndex.value], editedItem.value)
            } else {
                imageList.value.push(editedItem.value)
            }
            closeItem()
        }


        const title = (val) => {
            
            return editedIndex.value === -1 ? `New ${val} Image` : `Edit ${val} Image`
        }

        const skuTestVarietiesCount = ref(null)
        const skuTestImageHeaders = [
            {
                text: 'Label',
                align: 'start',
                value: 'label',
            },
            { text: 'Description (eg: "Test SKU 1 - KEY SKU")', value: 'description' },
            { text: 'Questions', value: 'questions' },
            { text: 'Price', value: 'price' },
            { text: 'Notes', value: 'notes' },
            { text: 'Actions', value: 'actions', sortable: false },
        ]

        const skuTests = ref([])
        const skuTestDialog = ref(false)
        const createSkuTests = (event, countryIdx=null) => {
            
            if (event) {
                event.preventDefault()
            }

            countryIdx == null ? countryIdx = tab.value : countryIdx
            skuTests.value = []
            for (var idx = 0; idx < skuTestVarietiesCount.value; idx++) {
                for(var i=0; i<localProjectData.value.countries[countryIdx].phase; i++) {
                    const skuImage = {
                        label: `SKU${idx+1}${String.fromCharCode(64 + i+1) }`,
                        description:idx == 0 ? `SKU ${idx+1} (Key SKU) Phase ${i+1}`:`SKU ${idx+1} Phase ${i+1}`,
                        questions:[],
                        lifesizePercent:100,
                        jpg:true,
                        prints:0,
                        price:true,
                        notes:""
                    }
                    skuTests.value.push(skuImage)
                }
            }
        }


        const skuCompetitors = ref([])
        const skuCompetitorsCount = ref(null)
        const skuCompetitorsImageHeaders = [
            {
                text: 'Label',
                align: 'start',
                value: 'label',
            },
            { text: 'Description (eg: "Competitor 1")', value: 'description' },
            { text: 'Questions', value: 'questions' },
            { text: 'Price', value: 'price' },
            { text: 'Notes', value: 'notes' },
            { text: 'Actions', value: 'actions', sortable: false },
        ]

        const createSkuCompetitors = (event) => {

            if (event) {
                event.preventDefault()
            }

            skuCompetitors.value = []
            for (var idx = 0; idx < skuCompetitorsCount.value; idx++) {
                const skuImage = {
                    label: `SKUX${idx+1}`,
                    description:`Competitor ${idx+1}`,
                    questions:[],
                    lifesizePercent:100,
                    jpg:true,
                    prints:0,
                    price:true,
                    notes:""
                }
                skuCompetitors.value.push(skuImage)
            }
        }



        const lines = ref([])
        const lineCount = ref(null)
        const lineImageHeaders = [
            {
                text: 'Label',
                align: 'start',
                value: 'label',
            },
            { text: 'Description (eg: "Test Line 1 - MAIN LINE")', value: 'description' },
            { text: 'Questions', value: 'questions' },
            { text: 'Price', value: 'price' },
            { text: 'Notes', value: 'notes' },
            { text: 'Actions', value: 'actions', sortable: false },
        ]

        const createLines = (event, countryIdx=null) => {
            if (event) {
                event.preventDefault()
            }

            countryIdx == null ? countryIdx = tab.value : countryIdx
            lines.value = []
            for(var i=0; i<localProjectData.value.countries[countryIdx].phase; i++) {
                for (var idx = 0; idx < lineCount.value; idx++) {
                    const skuImage = {
                        label: `${String.fromCharCode(64 + i+1) }${String.fromCharCode(64 + i+1) }${idx+1}`,
                        description:`Test Line ${idx+1} - Phase ${i+1}`,
                        questions:[],
                        lifesizePercent:100,
                        jpg:true,
                        prints:0,
                        price:true,
                        notes:""
                    }
                    lines.value.push(skuImage)
                }
            }
        }

        const lineCompetitors = ref([])
        const lineCompetitorCount = ref(null)
        

        const createLineCompetitors = (event) => {
            if (event) {
                event.preventDefault()
            }
            lineCompetitors.value = []
            for (var idx = 0; idx < lineCompetitorCount.value; idx++) {
                const skuImage = {
                    label: `XX${idx+1}`,
                    description:`Competitor Line ${idx+1}`,
                    questions:[],
                    lifesizePercent:100,
                    jpg:true,
                    prints:0,
                    price:true,
                    notes:""
                }
                lineCompetitors.value.push(skuImage)
            }
        }


        onMounted(() => {
            initialize()
        })

        watch(
            itemDialog,
            (val)=>{

                if (editedIndex.value === -1) {
                    const len = shelves.value.length
                    const item = {
                        label: `SHELF1${String.fromCharCode(64 + len+1) }`,
                        description:`Phase ${len+1}`,
                        products:[],
                        questions:[],
                        lifesizePercent:100,
                        jpg:true,
                        prints:0,
                        price:true,
                        boxed:false,
                        notes:""
                    }
                    editedItem.value = Object.assign({}, item)
                }
                val || closeItem()
            }
        )
        
        watch(
            itemDialogDelete,
            (val)=>{
                val || closeItemDelete()
            }
        )

        watch(
            () => props.projectData,
            () => {
                init()
            }
        )

        return {
            initialize,
            localProjectData,
            tab,
            shelfImageHeaders,
            countries,
            shelves,

            editedItem,
            title,
            itemDialog,
            itemDialogDelete,
            editedItem,
            closeItem,
            closeItemDelete,
            editItem,
            deleteItem,
            deleteItemConfirm,
            closeItem,
            closeItemDelete,
            saveItem,
            initCountrySwof,

            skuTestVarietiesCount,
            skuTests,
            skuTestDialog,
            skuTestImageHeaders,
            createSkuTests,

            skuCompetitors,
            skuCompetitorsImageHeaders,
            skuCompetitorsCount,
            createSkuCompetitors,

            lines,
            lineCount,
            lineImageHeaders,
            createLines,

            lineCompetitors,
            lineCompetitorCount,
            createLineCompetitors,

            icons: {
                mdiPencil,
                mdiDelete,
                mdiCheck
            }
        }
    }

}
</script>

<style scoped>
.swof-tab-overview {
    background-color: transparent !important;
}
#swof-tabs {
  background-color: transparent !important;
}

.country-tabs {
    background-color: white;
}

</style>