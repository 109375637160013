<template>
  <div>
    <v-row class="project-detail-panel">
      <!-- project profile -->
      <v-col cols="12">
        <v-card class="pt-10">
          <v-card-title class="justify-center flex-column">
            <v-avatar
              :color="projectDataLocal.color || 'primary'"
              class=" mb-4"
              size="120"
              rounded
            >
              <span
                class="font-weight-semibold text-3xl white--text"
              >{{ projectDataLocal.job_number }}</span>
            </v-avatar>

            <span class="mb-2">{{ projectDataLocal.job_name }}</span>

            <v-chip
              small
              label
              :color="resolveProjectStatusVariant(projectDataLocal.job_status.name)"
              :class="`v-chip-light-bg ${resolveProjectStatusVariant(projectDataLocal.job_status.name)}--text font-weight-medium text-capitalize`"
            >
              {{ projectDataLocal.job_status.name }}
            </v-chip>
          </v-card-title>

          <v-card-text class="d-flex justify-center flex-wrap mt-2 pe-sm-0">
            <div class="d-flex align-center me-4 mb-4">
              <v-tooltip 
                top
              >
                <template v-slot:activator="{ on, attrs }">
                  <div
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-btn
                    icon
                    @click="goToTimeline"
                    >
                      <v-avatar
                        size="40"
                        rounded
                        color="primary"
                        class="v-avatar-light-bg primary--text me-3"
                      >
                        <v-icon
                          color="primary"
                          size="22"
                        >
                          {{ icons.mdiChartTimeline }}
                        </v-icon>
                      </v-avatar>
                    </v-btn>
                  </div>
                </template>
                <span>Timeline Page</span>
              </v-tooltip> 
            </div>

            <div class="d-flex align-center mb-4 me-4">
              <v-tooltip 
                top
              >
                <template v-slot:activator="{ on, attrs }">
                  <div
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-btn
                    icon
                    @click="goToSharepoint(projectDataLocal.folder_web_url)"
                    >
                    <v-avatar
                      size="40"
                      rounded
                      color="primary"
                      class="v-avatar-light-bg primary--text me-3"
                    >
                      <v-icon
                        color="primary"
                        size="22"
                      >
                        {{ icons.mdiMicrosoftSharepoint  }}
                      </v-icon>
                    </v-avatar>
                    </v-btn>
                  </div>
                </template>
                <span>Sharepoint Page</span>
              </v-tooltip> 

            </div>
            <div class="d-flex align-center me-4 mb-4">
              <v-tooltip 
                top
              >
                <template v-slot:activator="{ on, attrs }">
                  <div
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-btn
                    icon
                    :loading="exportLoading"
                    @click="downloadTimeline(projectDataLocal)"
                    >
                      <v-avatar
                        size="40"
                        rounded
                        color="primary"
                        class="v-avatar-light-bg primary--text me-3"
                      >
                        <v-icon
                          color="primary"
                          size="22"
                        >
                          {{ icons.mdiFileExcelOutline }}
                        </v-icon>
                      </v-avatar>
                    </v-btn>
                  </div>
                </template>
                <span>Export Timeline</span>
              </v-tooltip> 
            </div>
          </v-card-text>

          <v-card-text>
            <h2 class="text-xl font-weight-semibold mb-2">
              Details
            </h2>

            <v-divider></v-divider>
            <v-list>
              <v-list-item
                dense
                class="px-0 mb-n2"
              >
                <span class="font-weight-medium me-2">Client:</span>
                <span class="text--secondary text-capitalize">{{ projectDataLocal.client.name }}</span>
              </v-list-item>
              <v-list-item
                dense
                class="px-0 mb-n2"
              >
                <span class="font-weight-medium me-2">Brand:</span>
                <span class="text--secondary">{{ projectDataLocal.brand }}</span>
              </v-list-item>

              <v-list-item
                dense
                class="px-0 mb-n2"
              >
                <span class="font-weight-medium me-2">Category:</span>
                <span class="text--secondary">{{ projectDataLocal.category.name }}</span>
              </v-list-item>

              <v-list-item
                dense
                class="px-0 mb-n2"
              >
                <span class="font-weight-medium me-2">Office:</span>
                <span class="text--secondary">{{ projectDataLocal.office.name }}</span>
              </v-list-item>

              <v-list-item
                dense
                class="px-0 mb-n2"
              >
                <span class="font-weight-medium me-2">Contact:</span>
                <span class="text--secondary"> {{ projectDataLocal.contact.name }}</span>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>

      <!-- project plan -->
      <v-col cols="12">
        <v-card
          :style="`border: 2px solid var(--v-${resolveProjectStatusVariant(projectDataLocal.job_status.name)}-base !important;`"
          flat
        >
          <v-card-title class="flex-start align-start justify-space-between pb-0">
            Budget

            <div class="project-pricing">
              <span :class="`text-5xl font-weight-semibold ${resolveProjectStatusVariant(projectDataLocal.job_status.name)}--text`">{{ projectDataLocal.budget.toLocaleString('fr-FR') }}</span>
              <sup :class="`${resolveProjectStatusVariant(projectDataLocal.job_status.name)}--text`">{{projectDataLocal.currency.name}}</sup>
            </div>
          </v-card-title>

          <v-card-text>
            <v-list class="pa-0">
              <v-list-item
                dense
                class="px-0 mb-n2"
              >
                <v-icon
                  size="10"
                  class="me-2"
                >
                  {{ icons.mdiCheckboxBlankCircle }}
                </v-icon>
                <span class="font-weight-medium me-2">Billing Rule:</span>
                <span class="text--secondary">{{ projectDataLocal.billing_rule.name }}</span>
              </v-list-item>
              <v-list-item
                dense
                class="px-0 mb-n2"
              >
                <v-icon
                  size="10"
                  class="me-2"
                >
                  {{ icons.mdiCheckboxBlankCircle }}
                </v-icon>
                <span class="font-weight-medium me-2">Gross Margin:</span>
                <span class="text--secondary" v-if="projectDataLocal.gross_margin">{{ projectDataLocal.gross_margin.toLocaleString('fr-FR') }}</span>
                <span class="text--secondary" v-else>{{ projectDataLocal.estimated_gross_margin?projectDataLocal.estimated_gross_margin.toLocaleString('fr-FR'): '' }}</span>
              </v-list-item>
              <v-list-item
                dense
                class="px-0 mb-n2"
              >
                <v-icon
                  size="10"
                  class="me-2"
                >
                  {{ icons.mdiCheckboxBlankCircle }}
                </v-icon>
                <span class="font-weight-medium me-2">OOP:</span>
                <span class="text--secondary">{{ projectDataLocal.oop?projectDataLocal.oop.toLocaleString('fr-FR'):0 }}</span>
              </v-list-item>
              <v-list-item
                dense
                class="px-0 mb-n2"
              >
                <v-icon
                  size="10"
                  class="me-2"
                >
                  {{ icons.mdiCheckboxBlankCircle }}
                </v-icon>
                <span class="font-weight-medium me-2">PO:</span>
                <span class="text--secondary">{{ projectDataLocal.po }}</span>
              </v-list-item>
              <v-list-item
                dense
                class="px-0 mb-n2"
                v-if="projectDataLocal.ges_bva"
              >
                <v-icon
                  size="10"
                  class="me-2"
                >
                  {{ icons.mdiCheckboxBlankCircle }}
                </v-icon>
                <span class="font-weight-medium me-2">GES BVA:</span>
                <span class="text--secondary">{{ projectDataLocal.ges_bva }}</span>
              </v-list-item>
              <v-list-item
                dense
                class="px-0 mb-n2"
                v-if="projectDataLocal.billed_by"
              >
                <v-icon
                  size="10"
                  class="me-2"
                >
                  {{ icons.mdiCheckboxBlankCircle }}
                </v-icon>
                <span class="font-weight-medium me-2">Billed By:</span>
                <span class="text--secondary">{{ projectDataLocal.billed_by.name }}</span>
              </v-list-item>
            </v-list>
            <p> </p>
            <v-btn
              :color="resolveProjectStatusVariant(projectDataLocal.job_status.name)"
              block
              :disabled="!projectDataLocal.editable"
              @click.stop="isFinancialDialogOpen = true"
            >
              Update Financials
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <project-financial-edit
      :projectData="projectData"
      :is-financial-dialog-open.sync="isFinancialDialogOpen"
      @update-project="updateProject"
    >
    </project-financial-edit>
  </div>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import { mdiCheck, mdiMicrosoftSharepoint, mdiCheckboxBlankCircle, mdiChartTimeline, mdiFileExcelOutline } from '@mdi/js'
import { avatarText, kFormatter } from '@core/utils/filter'
import useProjectsList from '../../project-list/useProjectsList'
import useProject from '../../useProject'
import ProjectFinancialEdit from './ProjectFinancialEdit.vue'

import router from '@/router'

export default {
  components: {
    ProjectFinancialEdit
  },
  props: {
    projectData: {
      type: Object,
      required: true,
    },
  },
  setup(props, {emit}) {
    
    const isFinancialDialogOpen = false

    const projectDataLocal = ref(JSON.parse(JSON.stringify(props.projectData)))

    const {
      resolveProjectStatusVariant
    } = useProjectsList()

    const {
      exportLoading,
      downloadTimeline
    } = useProject()

    const goToSharepoint = (url) => {
        window.open(url, '_blank')
    }

    const goToTimeline = () => {
      const route = router.resolve({
         path: `/timeline/project/${projectDataLocal.value.job_number}` 
      })
      window.open(route.href, '_blank')
    }

    const updateProject = (projectData) => {
      emit('update-project', projectData)
    }

    watch(
      () => props.projectData, 
      () => {
        projectDataLocal.value = JSON.parse(JSON.stringify(props.projectData))
      },
    )

    return {
      resolveProjectStatusVariant,
      avatarText,
      kFormatter,
      goToSharepoint,
      updateProject,
      isFinancialDialogOpen,
      projectDataLocal,
      goToTimeline,
      exportLoading,
      downloadTimeline,

      icons: {
        mdiCheck,
        mdiMicrosoftSharepoint,
        mdiCheckboxBlankCircle,
        mdiChartTimeline,
        mdiFileExcelOutline
      },
    }
  },
}
</script>
